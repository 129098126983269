import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingOverlay = (): JSX.Element => (
    <Box
        sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
        }}
    >
        <CircularProgress color="primary" />
    </Box>
);

export default LoadingOverlay;
